import { useQuery } from "@tanstack/react-query";
import api from "utils/api";


const fetchRankingAll = (worldType)=>{
  return api.get(`ranking/overall?world_type=${worldType}`)
}

// 랭킹 조회
export const useRankingAll = (worldType)=>{
  return useQuery({
    queryKey : ["ranking-all",worldType],
    queryFn : ()=> fetchRankingAll(worldType),
    retry : 1,
    select : (result) => result.data.ranking,
    staleTime : 60000
  })
}

const fetchRankingDojang = (world)=>{
  if(world && world !== "전체"){
    return api.get(`/ranking/dojang?&difficulty=1&world_name=${world}`)
  }
  return api.get('/ranking/dojang?&difficulty=1')
}

// 무릉도장 랭킹 조회
export const useRankingDojang = (world)=>{
  return useQuery({
    queryKey : ["ranking-dojang",world],
    queryFn : ()=> fetchRankingDojang(world),
    retry : 1,
    select : (result) => result.data.ranking,
    staleTime : 60000,
  })
}


// 더 시드 랭킹 조회
const fetchRankingTheseed = ()=>{
  return api.get('/ranking/theseed')
}

export const useRankingTheseed = ()=>{
  return useQuery({
    queryKey : ["ranking-theseed"],
    queryFn : ()=> fetchRankingTheseed(),
    retry : 1,
    select : (result) => result.data.ranking,
    staleTime : 60000
  })
}


// 업접 랭킹 조회
const fetchRankingAchievement = ()=>{
  return api.get('/ranking/achievement')
}

export const useRankingAchievement = ()=>{
  return useQuery({
    queryKey : ["ranking-achievement"],
    queryFn : ()=> fetchRankingAchievement(),
    retry : 1,
    select : (result) => result.data.ranking,
    staleTime : 60000
  })
}

// 유니온 랭킹 
const fetchRankingUnion = ()=>{
  return api.get('/ranking/union')
}

export const useRankingUnion = ()=>{
  return useQuery({
    queryKey : ["ranking-union"],
    queryFn : ()=> fetchRankingUnion(),
    retry : 1,
    select : (result) => result.data.ranking,
    staleTime : 60000
  })
}