import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";
import styles from "./SearchForm.module.scss";
import { useNavigate } from "react-router-dom";
const SearchForm = ({ placeholder }) => {
  const [keyword, setKeyword] = useState("");
  const navigate = useNavigate();
  const searchByKeyword = (event) => {
    event.preventDefault();
    navigate(`/user/${keyword}`);
  };
  return (
    <form className={styles.form} onSubmit={searchByKeyword}>
      <input
        type="text"
        placeholder={placeholder}
        className={styles.input}
        onChange={(event) => setKeyword(event.target.value)}
      />
      <button type="submit" className={styles.button}>
        <FontAwesomeIcon icon={faMagnifyingGlass} />
      </button>
    </form>
  );
};

export default SearchForm;
