const numberToKoreanUnit = (num) =>{
  if(!num){
    return 0
  }
  // 큰 자리수를 표현하는 단위 배열
  const units = ["", "만 ", "억 ", "조"];

  // 주어진 숫자를 배열로 변환
  const numArray = Array.from(String(num), Number);

  // 4자리 단위로 숫자를 나눈 섹션을 저장할 배열
  const sections = [];

  // 주어진 숫자를 4자리 단위로 나누어 섹션 배열에 저장
  while (numArray.length > 0) {
    sections.push(parseInt(numArray.splice(-4).join(""), 10));
  }

  // 최종 결과를 저장할 변수
  let result = "";

  // 각 섹션에 대해 처리
  sections.map((section, index) => {
    // 섹션이 0이 아닌 경우에만 처리
    if (section !== 0) {
      // 결과에 섹션과 해당하는 큰 자리수 단위 추가
      result = section + units[index] + result;
    }
  });

  // 결과가 없으면 '영', 0 반환
  return result || 0;
}

// 초를 분으로 변환
const convertSecondsToMinutesAndSeconds = (seconds) => {
    let minutes = Math.floor(seconds / 60);
    let remainingSeconds = seconds % 60;

    return `${minutes}분 ${remainingSeconds}초`
}

// 숫자 4자리 단위 콤마 찍기
const formattedNumber = (number) => {
  if(number){
    return number.toLocaleString();
  }
}

// 오늘 날짜 출력
// 현재 날짜를 얻기 위해 Date 객체를 사용합니다.
const formattedDate = () => {
  var today = new Date();
  
  // 연, 월, 일을 얻습니다.
  var year = today.getFullYear();
  // 월은 0부터 시작하므로 1을 더합니다.
  var month = (today.getMonth() + 1).toString().padStart(2, '0');
  var day = (today.getDate() - 1).toString().padStart(2, '0');
  
  // 결과를 출력합니다.
  var formattedDate = year + "-" + month + "-" + day;
  
  // console.log("오늘 날짜: " + formattedDate);
  return formattedDate;
}

const formattedDatePice = () => {
  var today = new Date();
  
  // 연, 월, 일을 얻습니다.
  var year = today.getFullYear();
  // 월은 0부터 시작하므로 1을 더합니다.
  var month = (today.getMonth() + 1).toString()
  var day = (today.getDate() - 1).toString()
  
  // 결과를 출력합니다.
  // var formattedDate = year + "-" + month + "-" + day;
  
  // console.log("오늘 날짜: " + formattedDate);
  return {
    month,
    day
  }
}


export {
  numberToKoreanUnit,
  convertSecondsToMinutesAndSeconds,
  formattedNumber,
  formattedDate,
  formattedDatePice
}


