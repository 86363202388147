import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import styles from "./RankBestThree.module.scss";
import { useRanksPower } from "../../../../hook/useRankPower";
import {
  useCharatcerOcid,
  useCharatcerOcidList,
} from "../../../../hook/useCharacterOcid";
import { useCharacterStatList } from "../../../../hook/useCharacterStat";
import { numberToKoreanUnit } from "../../../../js/common";
const RankBestThree = () => {
  const { data: ranksData } = useRanksPower(0);
  // const { data: ocidData } = useCharatcerOcidList(ranksData);
  const { data: ocidData } = useCharatcerOcidList(ranksData || []);
  const { data: statData } = useCharacterStatList(ocidData);

  return (
    <>
      <div className={`${styles.title}`}>
        <Link
          to="/"
          className={`${styles.title__link} flex-box align-center justify-bet color-white`}
        >
          <p>전투력 랭킹</p>
          <FontAwesomeIcon icon={faChevronRight} />
        </Link>
      </div>
      <ul className={styles.topThree}>
        {ranksData
          ?.filter((el, index) => index < 3)
          .map((el, index) => (
            <li
              className={`${styles.topThree__item} flex-box justify-bet align-center`}
              key={index}
            >
              <div className="flex-box align-center gap-6">
                <span>{index + 1}</span>
                <p className={styles.topThree__name}>{el}</p>
              </div>
              <p className={styles.topThree__power}>
                {/* {numberToKoreanUnit(99999999)} */}
                {statData &&
                  numberToKoreanUnit(
                    statData[index]?.data.final_stat[42].stat_value
                  )}
              </p>
            </li>
          ))}
      </ul>
    </>
  );
};

export default RankBestThree;
