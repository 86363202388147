import React, { useState } from "react";
import styles from "./Board.module.scss";
import BoardList from "./BoardList";
import { useRankingAll, useRankingDojang } from "hook/useRanking";
import { useNewsUpdate } from "hook/useMaplestoryUpdate";
import { formattedDatePice } from "js/common";
const Board = () => {
  const [selectWorld, setSelectWorld] = useState("전체");
  const { data: normalWordData, isLoading: normalLoading } = useRankingAll(0);
  const { data: rebootWordData, isLoading: rebootLoading } = useRankingAll(1);
  const {
    data: dojangData,
    isLoading: dojangLoading,
    refetch,
  } = useRankingDojang(selectWorld);
  const { data: newsUpdateData, isLoading: mapleUpdataLoading } =
    useNewsUpdate();
  if (normalLoading || rebootLoading || dojangLoading || mapleUpdataLoading) {
    return <h1>Loading...</h1>;
  }

  const { month, day } = formattedDatePice();
  const boardInfo = [
    {
      type: "normal",
      title: `${month}월 ${day}일 <strong>일반 월드</strong> 랭킹`,
      data: normalWordData?.filter((item, index) => index < 7),
    },
    {
      type: "reboot",
      title: `${month}월 ${day}일 <strong>리부트 월드</strong> 랭킹`,
      data: rebootWordData?.filter((item, index) => index < 7),
    },
    {
      type: "dojang",
      title: `${month}월 ${day}일 <strong>무릉도장</strong> 랭킹`,
      data: dojangData?.filter((item, index) => index < 7),
      dojangWorld: ["전체", "루나", "스카니아", "엘리시움", "리부트"],
      handleWorld: function (world) {
        setSelectWorld(world);
        refetch();
      },
      selectWorld,
    },
    {
      type: "update",
      title: "<strong>업데이트</strong> 정보",
      data: newsUpdateData.filter((item, index) => index < 9),
    },
  ];
  return (
    <section className={`${styles["board-list"]} grid-box`}>
      {boardInfo.map((info, index) => (
        <BoardList info={info} key={index} />
      ))}
    </section>
  );
};

export default Board;
