import { useQuery } from "@tanstack/react-query";
import axios from "axios";
const cheerio = require("cheerio");

const fetchRanksPower = async (page)=>{
  let ulList = [];
  // const html = await axios.get(`http://dev.kcwmonster.com/ranks/power?page=${page}`)
  const html = await axios.get(`http://dev.kcwmonster.com/maplegg/ranks/power?page=${page}`)
  const $ = cheerio.load(html.data);
  const $table = $('.gRvApU tbody tr');
  $table.map((i, node) => {
    ulList.push($(node).find('.name').text().split('Lv')[0].trim())
  });
  return ulList;
}

export const useRanksPower = (page)=>{
  return useQuery({
    queryKey : ['rank-power', page],
    queryFn :()=> fetchRanksPower(page),
    retry : 1,
    staleTime : 60000,
  })
}
