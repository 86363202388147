export const serverIcon = (wordName) =>{
  switch (wordName) {
    case "전체":
      return 'ico-world-all.png';
    case "루나":
      return 'ico_world_luna.gif';
    case "스카니아":
      return 'ico_world_scania.gif';
    case "엘리시움":
      return 'ico_world_elysium.gif';
    case "크로아":
      return 'ico_world_croa.gif';
    case "오로라":
      return 'ico_world_aurora.gif';
    case "베라":
      return 'ico_world_bera.gif';
    case "레드":
      return 'ico_world_red.gif';
    case "유니온":
      return 'ico_world_union.gif';
    case "제니스":
      return 'ico_world_zenith.gif';
    case "이노시스":
      return 'ico_world_enosis.gif';
    case "아케인":
      return 'ico_world_arcane.gif';
    case "노바":
      return 'ico_world_nova.gif';
    case "리부트":
      return 'ico_world_reboot.gif';
  }
}
