import React from "react";
import { Link } from "react-router-dom";
import styles from "./Navbar.module.scss";
import SearchForm from "../../component/common/SearchForm/SearchForm";
const Navbar = () => {
  const menuList = [
    "메인",
    "가이드",
    "유용한 도구",
    "트렌드",
    "순위표",
    "즐겨찾기",
    "새소식",
    "닥지지 카드",
  ];
  return (
    <>
      <header className={styles.navbar}>
        <div className="inner">
          <div
            className={`${styles.navbar__cont} flex-box align-center justify-bet`}
          >
            <h1 className="color-white">
              <Link to="/">Maple.GG 메이플지지</Link>
            </h1>
            <div className={`${styles["search-form"]} search-form`}>
              <SearchForm placeholder="캐릭터 또는 길드" />
            </div>
          </div>
        </div>
        <div className={styles.gnb}>
          <ul className={`${styles.gnb__list} inner flex-box`}>
            {menuList.map((menu, index) => (
              <li key={index}>
                <Link to="/" className={`${styles.gnb__item}`}>
                  {menu}
                </Link>
              </li>
            ))}
          </ul>
        </div>
      </header>
    </>
  );
};

export default Navbar;
