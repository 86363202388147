import { useQuery } from "@tanstack/react-query";
import axios from "axios";
const cheerio = require("cheerio");


const fetchNewsUpdate = async ()=>{
  let ulList = [];
  const html = await axios.get('http://dev.kcwmonster.com/maplestory/News/Update');
  const $ = cheerio.load(html.data);
  const $list = $('.update_board li');
  $list.map((i, node) => {
    ulList.push({
      title : $(node).find('a').text(),
      link : $(node).find('a').attr('href'),
      bedge : "본섭"
    })
  });
  return ulList;
}

export const useNewsUpdate = () =>{
  return useQuery({
    queryKey : ['maplestory-update'],
    queryFn : ()=> fetchNewsUpdate(),
    staleTime : 60000
  })  
}

