import React from "react";
import styles from "./Footer.module.scss";
import { Link } from "react-router-dom";

const Footer = () => {
  const footerMenu = [
    "서비스 이용 약관",
    "개인정보 취급방침",
    "제휴 문의",
    "고객센터",
    "채용",
  ];
  return (
    <footer>
      <div className="inner">
        <section className={styles.logo}>
          <Link to="/" className={styles.logo}>
            Maple.GG
          </Link>
        </section>
        <section className={styles.info}>
          <ul className={`${styles.menu} flex-box`}>
            {footerMenu.map((item, index) => (
              <li key={index}>
                <Link to="/">{item}</Link>
              </li>
            ))}
          </ul>
          <p className={styles.copylight}>
            © All Rights Reserved. TEST is not associated with NEXON Korea. Data
            based on NEXON Open API.
          </p>
        </section>
      </div>
    </footer>
  );
};

export default Footer;
