import { useQueries, useQuery } from "@tanstack/react-query";
import api from "../utils/api";
import { queries } from "@testing-library/react";

// 캐릭터 기본 정보
const fetchCharacterBasic = (ocid) => {
	return api.get(`character/basic?ocid=${ocid}`)
}

export const useCharacterBasic = (ocid) => {
	return useQuery({
		queryKey: ['character-basic', ocid],
		queryFn: () => fetchCharacterBasic(ocid),
		select: (result) => result.data,
		staleTime: 60000,
		enabled : !!ocid
	})
}


// 캐릭터 캐시 아이템
const fetchCharacterCashImte = (ocid) => {
	return api.get(`/character/cashitem-equipment?ocid=${ocid}`)
}
export const useCharacterCashItem = (ocid) => {
	return useQuery({
		queryKey : ['character-cashItem',ocid],
		queryFn : ()=> fetchCharacterCashImte(ocid),
		staleTime : 60000,
		enabled : !!ocid,
		select : (result) => {
			switch (result.data.preset_no) {
				case 1:
					return result.data.cash_item_equipment_preset_1
				case 2:
					return result.data.cash_item_equipment_preset_2
				case 3:
					return result.data.cash_item_equipment_preset_3
			}
		}
	})
}

// 캐릭터 인기도
const fetchCharacterPopularity = (ocid) => {
	return api.get(`/character/popularity?ocid=${ocid}`)
}
export const useCharacterPopularity = (ocid) => {
	return useQuery({
		queryKey : ['character-popularity',ocid],
		queryFn : ()=>fetchCharacterPopularity(ocid),
		staleTime : 60000,
		select : (result) => result.data
	})
}

// const fetchCharacterBasicList = async (ocid) => {
// 	return api.get(`character/basic?ocid=${ocid}&date=2024-02-25`);
// }

// export const useCharacterBasicList = (ocid) => {
// 	return useQueries({
// 		queries: ocid.map((ocid) => {
// 			return {
// 				queryKey: ['character-basic-list', ocid],
// 				queryFn: () => fetchCharacterBasicList(ocid),
// 				staleTime: 60000
// 			}
// 		}),
// 		combine: (results) => {
// 			return {
// 				data: results.map((results) => results.data?.data)
// 			}
// 		}
// 	})
// }