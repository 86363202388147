import React from "react";
import styles from "./CharacterInfo.module.scss";
import evan from "./images/img-evan-round.png";
const CharacterInfo = () => {
  return (
    <div className={`${styles.wrap} inner`}>
      <div className={styles.desc}>
        <div>
          <img src={evan} alt="" />
        </div>
        <div className={styles["txt-box"]}>
          <p>
            캐릭터 스탯 및 장비 정보는 2023.12.21 이후 접속한 계정의 캐릭터만
            확인이 가능합니다.
          </p>
          <p>캐릭터 정보는 매일 오전 1시에 갱신됩니다.</p>
        </div>
      </div>
      <ul className={styles.tab}>
        <li className={styles["is-active"]}>
          <button>스탯/장비</button>
        </li>
        <li>
          <button>유니온</button>
        </li>
        <li>
          <button>스킬 및 심볼</button>
        </li>
        <li>
          <button>본캐/부캐</button>
        </li>
      </ul>
    </div>
  );
};

export default CharacterInfo;
