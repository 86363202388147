import {
  useCharacterBasic,
  useCharacterCashItem,
  useCharacterPopularity,
} from "hook/useCharacterBasic";
import { useCharatcerOcid } from "hook/useCharacterOcid";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import styles from "./Banner.module.scss";
import bg from "./images/bg.jpg";
import { serverIcon } from "js/serverIcon";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar } from "@fortawesome/free-solid-svg-icons";

const Banner = () => {
  const params = useParams();
  const { data: ocidData } = useCharatcerOcid(params.id);
  const { data: characterData } = useCharacterBasic(ocidData);
  const { data: cashItem } = useCharacterCashItem(ocidData);
  const { data: popularity } = useCharacterPopularity(ocidData);
  console.log("popularity");
  let imagePath = process.env.PUBLIC_URL;
  const [favorites, setFavorites] = useState(false);
  return (
    <section
      className={styles.banner}
      style={{ backgroundImage: "url(" + `${bg}` + ")" }}
    >
      <div className="inner">
        <div className={`${styles.info} flex-box`}>
          <article className={styles.codyInfo}>
            <ul className={styles.list}>
              {cashItem?.map((item, index) => (
                <li className="flex-box" key={index}>
                  <span>{item.cash_item_equipment_slot}</span>
                  <p>{item.cash_item_name}</p>
                </li>
              ))}
            </ul>
          </article>
          <article className={styles.thumbnail}>
            <img src={characterData?.character_image} alt="" width={130} />
          </article>
          <article className={styles.specInfo}>
            <div className="flex-box align-center gap-8">
              <p className={styles.nickname}>{characterData?.character_name}</p>
              <span className={styles.world}>
                <img
                  src={`${imagePath}/images/world/${serverIcon(
                    characterData?.world_name
                  )}`}
                  alt=""
                />
                {characterData?.world_name}
              </span>
            </div>
            <ul className={`${styles["spec-list"]} flex-box`}>
              <li>Lv.{characterData?.character_level}</li>
              <li>{characterData?.character_class}</li>
              <li>인기도 {popularity?.popularity}</li>
              <li>{characterData?.character_guild_name}</li>
            </ul>
            <ul className={`${styles.btns} flex-box gap-8`}>
              <li>
                <button type="button" className={styles["btns-info"]}>
                  최신 정보
                </button>
              </li>
              <li>
                <button type="button" className={styles["btns-profile"]}>
                  프로필 저장
                </button>
              </li>
              <li>
                <button
                  type="button"
                  className={`${styles["btns-favorites"]} ${
                    favorites ? styles["is-active"] : ""
                  } `}
                  onClick={() => setFavorites(true)}
                >
                  <FontAwesomeIcon icon={faStar} />
                </button>
              </li>
            </ul>
          </article>
        </div>
      </div>
    </section>
  );
};

export default Banner;
