import { useQueries, useQuery } from "@tanstack/react-query";
import api from "../utils/api";


const fetchCharacterStatList = async (ocid)=>{
  return await api.get(`/character/stat?ocid=${ocid}`);
}
export const useCharacterStatList = (ocidData) =>{
  return useQueries({
    queries : ocidData.map((data)=>{
      return {
        queryKey : ["character-stat-list", data],
        queryFn : ()=>fetchCharacterStatList(data.ocid),
        staleTime : 60000,
      }
    }),
    combine : (results) =>{
      return {
        data : results.map((result) => result.data)
      }
    }
  })
}
