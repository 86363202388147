import React from "react";
import Banner from "./component/Banner/Banner";
import WeeklyRanking from "./component/WeeklyRanking/WeeklyRanking";
import styles from "./Homepage.module.scss";
import Board from "./component/Board/Board";
const Homepage = () => {
  return (
    <div>
      <Banner />
      <div className={`${styles["main-cont"]}`}>
        <div className={`${styles.inner} inner`}>
          <WeeklyRanking />
          <Board />
        </div>
      </div>
    </div>
  );
};

export default Homepage;
