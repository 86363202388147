import React, { useEffect, useState } from "react";
import styles from "./BoardList.module.scss";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { convertSecondsToMinutesAndSeconds } from "js/common";
import { serverIcon } from "js/serverIcon";
import { useRankingDojang } from "hook/useRanking";

const BoardList = ({ info }) => {
  const [selectWorld, setSelectWorld] = useState("전체");
  let imagePath = process.env.PUBLIC_URL;
  return (
    <>
      <article className={`${styles.list} ${info.type}`}>
        <div className={`${styles.title} flex-box justify-bet align-center`}>
          <p dangerouslySetInnerHTML={{ __html: info.title }}></p>
          <Link to="/" className={styles.more}>
            더 보기
            <FontAwesomeIcon icon={faChevronRight} />
          </Link>
        </div>
        {info.type == "dojang" ? (
          <ul className={`${styles["world-btn"]} flex-box`}>
            {info.dojangWorld.map((world, index) => (
              <li
                key={index}
                className={
                  info.selectWorld == world ? `${styles["is-active"]}` : ""
                }
              >
                <button onClick={() => info.handleWorld(world)}>
                  <img
                    src={`${imagePath}/images/world/${serverIcon(world)}`}
                    alt=""
                  />
                  {world}
                </button>
              </li>
            ))}
          </ul>
        ) : (
          <></>
        )}

        {info.type != "update" ? (
          <table className={styles.table}>
            <colgroup>
              <col width={50} />
              <col />
              <col width={50} />
              <col width={90} />
              {info.type == "dojang" ? <col width={50} /> : <col width={90} />}
              {info.type == "dojang" ? <col width={90} /> : ""}
            </colgroup>
            <thead>
              <tr>
                <th>#</th>
                <th>캐릭터</th>
                <th>레벨</th>
                <th>직업</th>
                {info.type == "dojang" ? (
                  <>
                    <th>층수</th>
                    <th>기록</th>
                  </>
                ) : (
                  <th>길드</th>
                )}
              </tr>
            </thead>
            <tbody>
              {info.data?.map((item, index) => (
                <tr key={index}>
                  <>
                    <td>
                      <p
                        className={`${styles.order} ${
                          styles[`ranking-${index + 1}`]
                        }`}
                      >
                        {index + 1}
                      </p>
                    </td>
                    <td>{item.character_name}</td>
                    <td>{item.character_level}</td>
                    <td>
                      {item.sub_class_name == ""
                        ? item.class_name
                        : item.sub_class_name}
                    </td>
                    {info.type == "dojang" ? (
                      <>
                        <td>{item.dojang_floor}층</td>
                        <td>
                          {convertSecondsToMinutesAndSeconds(
                            item.dojang_time_record
                          )}
                        </td>
                      </>
                    ) : (
                      <td>{item.character_guildname}</td>
                    )}
                  </>
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <div className="update__list">
            <ul>
              {info.data.map((item, index) => (
                <li className="update__item" key={index}>
                  <Link to={item.link} className="update__link">
                    <span className="update__bedge">{item.bedge}</span>
                    <p className="update__title">{item.title}</p>
                  </Link>
                </li>
              ))}
            </ul>
          </div>
        )}
      </article>
    </>
  );
};

export default BoardList;
