import { useCharacterBasic } from "hook/useCharacterBasic";
import { useCharatcerOcid } from "hook/useCharacterOcid";
import React from "react";
import styles from "./UserDetailPage.module.scss";
import Banner from "./component/Banner/Banner";
import CharacterInfo from "./component/CharacterInfo/CharacterInfo";

const UserDetailPage = () => {
  return (
    <>
      <Banner />
      <div className={styles.characterWrap}>
        <CharacterInfo />
      </div>
    </>
  );
};

export default UserDetailPage;
