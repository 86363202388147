import React, { useEffect, useState } from "react";
import styles from "./WeeklyRanking.module.scss";
import WeeklyRankingCard from "./WeeklyRankingCard";

import { convertSecondsToMinutesAndSeconds, formattedNumber } from "js/common";
import {
  useRankingAchievement,
  useRankingDojang,
  useRankingTheseed,
  useRankingUnion,
} from "hook/useRanking";
const WeeklyRanking = () => {
  const { data: dojang, isLoading: dojangLoading } = useRankingDojang();
  const { data: theseed, isLoading: theseedLoading } = useRankingTheseed();
  const { data: achievement, isLoading: achievementLoading } =
    useRankingAchievement();
  const { data: union, isLoading: unionLoading } = useRankingUnion();
  if (dojangLoading || theseedLoading || achievementLoading || unionLoading) {
    return <h1>Loading</h1>;
  }

  const info = [
    {
      title: "무릉도장",
      type: "dojang",
      characterName: dojang[0].character_name,
      grade: `${dojang[0].dojang_floor}층`,
      point: `${convertSecondsToMinutesAndSeconds(
        dojang[0].dojang_time_record
      )}`,
    },
    {
      title: "더 시드",
      type: "theseed",
      characterName: theseed[0].character_name,
      grade: `${theseed[0].theseed_floor}층`,
      point: `${convertSecondsToMinutesAndSeconds(
        theseed[0].theseed_time_record
      )}`,
    },
    {
      title: "업적",
      type: "achievement",
      characterName: achievement[0].character_name,
      grade: achievement[0].trophy_grade,
      point: `${formattedNumber(achievement[0].trophy_score)}점`,
    },
    {
      title: "유니온",
      type: "union",
      characterName: union[0].character_name,
      grade: `Lv.${formattedNumber(union[0].union_level)}`,
      point: `전투력 ${formattedNumber(union[0].union_power)}`,
    },
  ];

  return (
    <section className={`${styles.weeklyRanking}`}>
      <ul className={`${styles.list} grid-box gap-20`}>
        {info.map((item, index) => (
          <WeeklyRankingCard
            key={index}
            title={item.title}
            type={item.type}
            characterName={item.characterName}
            grade={item.grade}
            point={item.point}
          />
        ))}
      </ul>
    </section>
  );
};

export default WeeklyRanking;
