import React from "react";
import styles from "./WeeklyRankingCard.module.scss";
import { Link } from "react-router-dom";
import { useCharatcerOcid } from "hook/useCharacterOcid";
import { useCharacterBasic } from "hook/useCharacterBasic";
import { serverIcon } from "js/serverIcon";
const WeeklyRankingCard = ({ title, type, characterName, grade, point }) => {
  const { data: ocid } = useCharatcerOcid(characterName);
  const { data: characterInfo } = useCharacterBasic(ocid);
  let imagePath = process.env.PUBLIC_URL;
  return (
    <li className={`${styles.item} ${styles[type]}`}>
      <h2 className={styles.title}>이번 주 {title} 1위</h2>
      <div className={styles.characterInfo}>
        <div className={styles.player}>
          <img
            src={`${imagePath}/images/world/${serverIcon(
              characterInfo?.world_name
            )}`}
            alt={characterInfo?.world_name}
          />
          <span className={styles.name}>{characterInfo?.character_name}</span>
          <span className={styles.level}>
            Lv.{characterInfo?.character_level}
          </span>
          <span className={styles.job}>{characterInfo?.character_class}</span>
        </div>
        <div className="character-image">
          <img
            src={characterInfo?.character_image}
            alt={characterInfo?.character_name}
          />
        </div>
        <p className={styles.grade}>{grade}</p>
        <p className={styles.point}>{point}</p>
      </div>
      <Link to="/" className={`${styles.link} color-white`}>
        상세보기
      </Link>
    </li>
  );
};

export default WeeklyRankingCard;
