import { useQueries, useQuery } from "@tanstack/react-query";
import api from "../utils/api";

const fetchCharacterOcid = async (characterNname) => {
	return await api.get(`/id?character_name=${characterNname}`)
}

export const useCharatcerOcid = (characterNname) => {
	return useQuery({
		queryKey: ['charachter-ocid', characterNname],
		queryFn: () => fetchCharacterOcid(characterNname),
		select: (result) => result.data.ocid,
		staleTime: 60000
	})
}


const fetchCharacterOcidList = async (characterName) => {
	return await api.get(`/id?character_name=${characterName}`)
}

export const useCharatcerOcidList = (ranksData) => {
	return useQueries({
		queries: ranksData.filter((item, index) => index < 3).map((characterName) => {
			return {
				queryKey: ["charachter-ocid", characterName],
				queryFn: () => fetchCharacterOcidList(characterName),
				staleTime: 60000
			}
		}),
		combine: (results) => {
			return {
				data: results.map((result) => result.data?.data)
			}
		},
	})
}