import './App.css';
import './scss/common.scss';
import './scss/news.scss';
import { Route, Routes } from 'react-router-dom';
import Applayout from './layout/Applayout';
import Homepage from './pages/Homepage/Homepage';
import UserDetailPage from './pages/UserDetail/UserDetailPage';
import NotFoundPage from './pages/NotFound/NotFoundPage';
function App() {
  return (
    <Routes>
      <Route path='/' element={<Applayout/>} >
        <Route index element={<Homepage/>}/>
        <Route path='user'>
          <Route path=':id' element={<UserDetailPage/>}/>
        </Route>
      </Route>

      <Route path='*' element={<NotFoundPage/>}/>
    </Routes>
  )
}

export default App;


