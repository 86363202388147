import React from "react";
import styles from "./Banner.module.scss";
import mainVisual from "./images/main-visual.jpg";
import { Link } from "react-router-dom";
import SearchForm from "../../../../component/common/SearchForm/SearchForm";
import RankBestThree from "../RankBestThree/RankBestThree";
const Banner = () => {
  return (
    <article
      style={{
        backgroundImage: "url(" + `${mainVisual}` + ")",
      }}
      className={styles.banner}
    >
      <div className={styles.banner__cont}>
        <div className={styles.logo}>
          <Link to="/">
            <img
              src="https://cdn.dak.gg/maple/images/logo/logo-full-maplegg-white.svg"
              alt=""
            />
          </Link>
        </div>
        <div className={`${styles["search-form"]} search-form`}>
          <SearchForm placeholder="캐릭터 또는 길드명을 입력하세요. 22" />
        </div>
        <RankBestThree />
      </div>
    </article>
  );
};

export default Banner;
